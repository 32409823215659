export const popupData = {
  polyèdre: {
    content:
      "Un polyèdre est un objet ou un solide possèdant au minimum 4 faces.",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/a/a4/Dodecahedron.svg",
  },
  vertex: {
    content:
      "Vertex, vertices ou sommet en français est une extrémité formée par la rencontre de deux arêtes ou plus. Un cube possède 8 vertices.",
  },
  edge: {
    content:
      "Les edges ou arêtes sont les lignes/connexions entre deux vertices. Un cube en possède 12.",
  },
  face: {
    content:
      "Une face est un polygone. Elle se crée à partir de 3 edges et 3 vertices (le triangle).",
  },
  viewport: {
    content:
      "Le viewport est le panneau de visualisation dans lequel vous pouvez voir votre modèle 3D et votre scène.",
    image: require("../img/viewport.png"),
  },
  timeline: {
    content:
      "La timeline est le panneau qui vous indique à quelle frame vous êtes dans votre animation. C'est aussi ici que vous pouvez ajouter, modifier ou supprimer des keyframes.",
    image: require("../img/timeline.png"),
  },
  outliner: {
    content:
      "L'outliner est le panneau qui vous permet de voir tous les objets de votre scène. Vous pouvez les sélectionner, les masquer ou les déplacer.",
    image: require("../img/outliner.png"),
  },
  properties: {
    content:
      "Le panneau properties vous permet de modifier les propriétés de vos objets, matériaux, lumières, etc. Mais aussi de modifier les paramètres de rendu et d'export.",
    image: require("../img/properties.png"),
  },
  lowpoly: {
    content:
      "Le low poly est un style de modélisation 3D qui consiste à créer des objets avec un nombre de polygones minimal.",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/a1002f89934319.5e125aa627c32.png",
  },
  cube: {
    content:
      "Le cube est un polyèdre régulier. Il possède 6 faces, 12 arêtes et 8 sommets.",
    image: require("../img/cube.png"),
  },
  sphère: {
    content:
      "La sphère est un objet géométrique en 3D. En théorie, elle n'a qu'une seule face, mais dans n'importe quel logiciel 3D, elle est composée de nombreuses faces, plus vous en rajouter, plus votre sphere sera ronde.",
    image: require("../img/sphere.png"),
  },
  cylindre: {
    content:
      "Le cylindre est un objet géométrique en 3D. Il possède 2 faces circulaires et une surface latérale.",
    image: require("../img/cylinder.png"),
  },
  cone: {
    content:
      "Le cone est un objet géométrique en 3D. Il possède une face circulaire et une surface latérale.",
    image: require("../img/cone.png"),
  },
  plane: {
    content:
      "Le plane est un objet géométrique en 2D. Il possède 1 faces, 4 arêtes et 4 sommets. Techniquement, il n'a pas de volume et n'est pas faisable dans la vraie vie. Pensez à une feuille de papier celle-ci est fine mais reste quelques micromètres.",
    // image: require("../img/plane.png"),
  },
  translate: {
    content:
      "Translate est une transformation qui déplace un objet dans l'espace 3D.",
    image: require("../img/translate.webp"),
  },
  rotate: {
    content:
      "Rotate est une transformation qui fait tourner un objet autour de son centre.",
    image: require("../img/rotate.webp"),
  },
  scale: {
    content:
      "Scale est une transformation qui agrandit ou réduit la taille d'un objet.",
    image: require("../img/scale.webp"),
  },
  loopcut: {
    image: require("../img/b-loopcut.gif"),
  },
  bevel: {
    image: require("../img/b-bevel.gif"),
  },
  subdivision: {
    image: require("../img/b-subdivide.gif"),
  },
  subdivide: {
    image: require("../img/b-subdivide.gif"),
  },
  extrude: {
    image: require("../img/b-extrude.gif"),
  },
  boolean: {
    image: require("../img/b-boolean.gif"),
  },
  array: {
    image: require("../img/b-array.gif"),
  },
  displacement: {
    // image: require("../img/b-displacement.webp"),
    content:
      "La displacement map est une texture en nuance de gris qui permet de déplacer les vertices d'un objet en fonction de la luminosité de l'image. Plus la couleur est claire, plus le vertex sera déplacé. A ne pas confondre avec la normal map.",
  },
  normalmap: {
    // image: require("../img/b-normalmap.webp"),
    content:
      "La normal map est une texture qui permet de simuler des détails en relief sur un objet 3D sans avoir à déplacer de polygones. Une normal map est une image comprenant les couleurs RGB, chaque couleur correspond à un une direction ce qui permet au moteur d'orienter les rayons en fonction. Cette technique est très utilisée dans le jeu vidéo.",
  },
  modifiers: {
    content:
      "Les modifiers sont des outils qui permettent de modifier la géométrie d'un objet sans avoir à toucher aux vertices. Ils sont non destructifs et peuvent être désactivés à tout moment.",
  },
  crease: {
    content:
      "Crease permet de définir la dureté des arêtes d'un objet. Plus la valeur est haute, plus l'arête sera dure.",
  },
  maps: {
    image: require("../img/maps.webp"),
    content:
      "Les maps sont des images qui permettent de texturer un objet. Il existe un grand nombre de maps différentes, chacune ayant un rôle spécifique. Les plus connues etant color, normal, displacement, roughness, metallic, etc.",
  },
  nodes: {
    content:
      "Les nodes sont des briques logiques qui permettent de créer des matériaux complexes, des géométries, des effets de compositing etc. Ils sont très utilisés dans les moteurs de rendu comme Cycles ou Eevee. C'est le coeur de Blender.",
  },
  principledbsdf: {
    image: require("../img/b-node-principled.png"),
    content:
      "Principled BSDF est un shader universel qui combine plusieurs shaders en un seul. Il est le node de base pour créer des matériaux réalistes.",
  },
  diffusebsdf: {
    image: require("../img/b-node-diffuse.png"),
    content:
      "Diffuse BSDF est un shader qui simule la diffusion de la lumière sur une surface mate.",
  },
  glassbsdf: {
    image: require("../img/b-node-glass.png"),
    content:
      "Glass BSDF est un shader qui simule la réfraction et la réflexion de la lumière à travers un matériau transparent.",
  },
  glossybsdf: {
    image: require("../img/b-node-glossy.png"),
    content:
      "Glossy BSDF est un shader qui simule la réflexion spéculaire sur une surface brillante.",
  },
  transparentbsdf: {
    image: require("../img/b-node-transparent.png"),
    content:
      "Transparent BSDF est un shader qui rend un matériau complètement transparent.",
  },
  translucentbsdf: {
    image: require("../img/b-node-translucent.png"),
    content:
      "Translucent BSDF est un shader qui simule la diffusion de la lumière à travers un matériau translucide. A ne pas confondre avec le Subsurface Scattering.",
  },
  subsurfacescattering: {
    image: require("../img/b-node-sss.png"),
    content:
      "Subsurface Scattering est un shader qui simule la diffusion de la lumière à l'intérieur d'un matériau, comme la peau ou la cire. Il va faire croire a un volume à votre objet.",
  },
  volumeprincipled: {
    image: require("../img/b-node-princivol.png"),
    content:
      "Volume Principled est un shader universel pour les volumes, combinant plusieurs effets en un seul.",
  },
  emission: {
    image: require("../img/b-node-emission.png"),
    content: "Emission est un shader qui fait qu'un objet émet de la lumière.",
  },
  refractionbsdf: {
    image: require("../img/b-node-refraction.png"),
    content:
      "Refraction BSDF est un shader qui simule la réfraction de la lumière à travers un matériau transparent ou non grâce à l'IOR.",
  },
  sheenbsdf: {
    image: require("../img/b-node-sheen.png"),
    content:
      "Sheen BSDF est un shader qui simule l'effet de brillance sur une surface, souvent utilisé pour les tissus. A ne pas confondre avec le Glossy BSDF.",
  },
  rayportalbsdf: {
    image: require("../img/b-node-rp.png"),
    content:
      "Ray Portal BSDF est un shader utilisé pour guider, dévier ou déplacer les rayons de lumière dans une scène.",
  },
  mixshader: {
    content:
      "Mix Shader est un node qui permet de mélanger deux shaders ensemble. Il est très utile pour créer des matériaux complexes.",
  },
  utilisernodewranglerpourmixer: {
    image: require("../img/b-mixshader-wrangler.gif"),
  },
  noisetexture: {
    image: require("../img/b-node-noise.png"),
    content:
      "Noise Texture est une texture qui génère du bruit. Surement la texture que vous utiliserez le plus.",
  },
  wavetexture: {
    image: require("../img/b-node-wave.png"),
    content:
      "Wave Texture est une texture qui génère des ondes. Elle permet de créer des motifs de vagues ou de ligne.",
  },
  colorramp: {
    image: require("../img/b-node-colorramp.png"),
    content:
      "Color Ramp est un node qui permet de contrôler la transition entre deux couleurs.",
  },
  math: {
    image: require("../img/b-node-math.png"),
    content:
      "Math est un node qui permet de faire des opérations mathématiques sur les valeurs.",
  },
  rgbtobw: {
    image: require("../img/b-node-rgbbw.png"),
    content:
      "RGB to BW est un node qui permet de convertir une image couleur en noir et blanc.",
  },
  separatexyz: {
    image: require("../img/b-node-separatexyz.png"),
    content: "Permet de séparer les valeurs X, Y et Z d'un vecteur.",
  },
  combinexyz: {
    image: require("../img/b-node-combinexyz.png"),
    content: "Permet de combiner les valeurs X, Y et Z en un seul vecteur.",
  },
  vectormath: {
    image: require("../img/b-node-vectormath.png"),
    content: "Permet de faire des opérations sur deux vecteurs.",
  },
  clamp: {
    image: require("../img/b-node-clamp.png"),
    content:
      "Permet de limiter les valeurs d'un vecteur ou d'une valeur ou d'une image.",
  },
  maprange: {
    image: require("../img/b-node-maprange.png"),
    content: "Permet de mapper les valeurs d'un vecteur ou d'une valeur.",
  },
  separatecolor: {
    image: require("../img/b-node-separatecolor.png"),
    content: "Permet de séparer les valeurs RGB d'une texture.",
  },
  combinecolor: {
    image: require("../img/b-node-combinecolor.png"),
    content: "Permet de combiner les valeurs RGB en une seule texture.",
  },
  floatcurve: {
    image: require("../img/b-node-floatcurve.png"),
    content: "Permet de modifier la courbe d'une valeur.",
  },
  blackbody: {
    image: require("../img/b-node-blackbody.png"),
    content: "Permet de simuler la lumière en degrés Kelvin.",
  },
  wavelength: {
    image: require("../img/b-node-wavelength.png"),
    content: "Permet de convertir une longueur d'onde en couleur.",
  },
  mapping: {
    image: require("../img/b-node-mapping.png"),
    content:
      "Le node Mapping permet de déplacer, tourner et redimensionner une texture.",
  },
  texturecoordinate: {
    image: require("../img/b-node-texturecoordinate.png"),
    content:
      "Texture Coordinate est un node qui permet de récupérer les coordonnées de texture d'un objet.",
  },
  normal: {
    image: require("../img/b-node-normal.png"),
    content:
      "Normal Map est un node qui permet de transformer une image en normal map.",
  },
  bump: {
    image: require("../img/b-node-bump.png"),
    content:
      "Bump est un node qui permet de simuler des détails en relief sur un objet.",
  },
  displacement: {
    image: require("../img/b-node-displacement.png"),
    content:
      "Displacement est un node qui permet de déplacer les vertices d'un objet en fonction d'une texture.",
  },
  bricktexture: {
    image: require("../img/b-node-brick.png"),
    content:
      "Brick Texture est une texture qui génère des briques. Elle est très utile pour créer des murs.",
  },
  checkertexture: {
    image: require("../img/b-node-checker.png"),
    content:
      "Checker Texture est une texture qui génère des damiers. Elle est très utile pour créer des motifs.",
  },
  gradienttexture: {
    image: require("../img/b-node-gradient.png"),
    content:
      "Gradient Texture est une texture qui génère un dégradé. Elle est très utile pour créer des transitions.",
  },
  magictexture: {
    image: require("../img/b-node-magic.png"),
    content:
      "Magic Texture est une texture qui génère des motifs aléatoires. Elle est très utile pour créer des textures organiques.",
  },
  voronoitexture: {
    image: require("../img/b-node-voronoi.png"),
    content:
      "Voronoi Texture est une texture qui génère des motifs de voronoi. Elle est très utile pour créer des textures organiques.",
  },
  skytexture: {
    image: require("../img/b-node-sky.png"),
    content:
      "Sky Texture est une texture qui génère un ciel. Elle est très utile pour créer des environnements.",
  },
  whitenoisetexture: {
    image: require("../img/b-node-whitenoise.png"),
    content: "White Noise Texture est une texture qui génère du bruit blanc.",
  },
};
